<template>
    <InitLayout>
      <b-card
          no-body
          class="d-flex flex-column align-items-center justify-content-center bg-transparent border-0 rounded-0 px-1 px-lg-4 py-3 login-container-card"
      >
        <!-- login form -->
        <template >
          <form
              id="login-form"
              class="d-flex flex-column align-items-start justify-content-center px-2 py-md-2 px-lg-1 w-100"
          >
            <b-row
              class="d-flex flex-row align-items-center justify-content-center w-100"
              no-gutters
            >
              <b-col cols="12" md="10">
                <FormInput
                  groupId="business-name-group"
                  id="business-name-group-input"
                  class="text-prime-gray rounded-2"
                  :state="businessnameError.status"
                  :invalidFeedback="`${businessnameError.message}`"
                  label="Add your business name"
                  v-model="businessname"
                  isRequired
                  :whiteBG="false"
                  trim
                  form="login-form"
                  type="text"
                ></FormInput>
              </b-col>
             </b-row>
             <b-row
              class="d-flex flex-nowrap flex-row align-items-center justify-content-center w-100"
              no-gutters
            >
              <b-col cols="12" md="10">
                <FormSelect
                  groupId="industry-group"
                  id="industry-group-input"
                  class="text-prime-gray rounded-2"
                  style="margin-left: 11px;margin-bottom: 0px;"
                  label="Choose your country"
                  :state="businessCountryError.status"
                  :invalidFeedback="`${businessCountryError.message}`"
                  v-model="businessCountry"
                  :whiteBG="false"
                  :options="countryList"
                  form="login-form"
                ></FormSelect>
                <b-tooltip target="info-icon-country-select" placement="top" >
                  *by selecting this field, prices will reflect the packages in the respective country currency
                </b-tooltip>
              </b-col>
              <b-icon
                  id="info-icon-country-select"
                  icon="info-circle-fill"
                  variant="info"
                  size="sm"
                  class="no-fill-icon"
                ></b-icon>
        
          </b-row>
           <!-- form action -->
              <b-row
                class="d-flex flex-row align-items-center justify-content-center w-100"
                no-gutters
              >
                <!-- Next button -->
                <b-col
                  class="d-flex flex-column align-items-center justify-content-center px-0 py-2"
                  cols="12"
                  md="10"
                >
                  <FormButton
                    variant="main-green"
                    :disabled="isEnabled"
                    isBlock
                    isPill
                    class="font-weight-normal text-secondary mt-4 mb-4"
                    type="submit"
                    @click.native="nextFn"
                    ><span class="text-white">Next</span></FormButton
                  >
                </b-col>
              </b-row>
          </form>
        </template>
      </b-card>
      <ToastNotification/>
    </InitLayout>
  </template>
  
  <script>
  // services
  //   import { LoginNew } from "../services/login.service";
  // import axios from "axios";
  import { mapActions} from "vuex";
  import { BIcon, BTooltip } from 'bootstrap-vue';
  // components
  // @ is an alias to /src
  import InitLayout from "@/layout/InitLayout";
  import FormInput from "@/components/Form/FormInput";
//   import FormInputGroup from "@/components/Form/FormInputGroup";
  import FormButton from "@/components/Form/FormButton";
  // import CheckEmail from "@/util/CheckEmail";
  import ToastNotification from "@/components/common/ToastNotification";
  import FormSelect from "@/components/Form/FormSelect";
  
  export default {
    name: "RegisterBusiness",
    components: {
      InitLayout,
      FormInput,
    //   FormInputGroup,
      FormButton,
      ToastNotification,
      BIcon,
      BTooltip,
      FormSelect
    },
    data() {
      return {
        isEnabled:true,
        businessname: null,
        businessCountry: "New Zealand",
        countryList: ["New Zealand", "Australia", "Fiji"],
        vatLabel:'GST Rate',
        businessnameError: {
          status: null,
          message: "",
        },
        businessCountryError: {
          status: null,
          message: "",
        },
        domain:process.env.VUE_APP_DOMAIN
    
      };
    },
    mounted() {
      this.checkOAuthCookies()
    },
    computed:{
      computedIsEnabled() {
      if (
        this.businessname &&
        this.businessCountry
      ){
        return true;
      }
      return false;
    },
    },
   
    watch: {
      businessname(value) {
        if (value) {
          this.businessnameError.status = true;
        } else {
          this.isEnabled = false;
          this.businessname = undefined;
          this.businessnameError.status = false;
          this.businessnameError.message = "Please fill out this field";
        }
      },
      businessCountry(value) {
        if (value === "New Zealand" || value === "Australia") {
          this.vatLabel = "GST Rate";
        } else if (value === "Fiji") {
          this.vatLabel = "VAT Rate";
        }
      },
      computedIsEnabled(value) {
      if (value) {
        this.isEnabled = false;
      }else{
        this.isEnabled =true;
      }
    },
    },
    methods: {
      ...mapActions({
      setIsAuthenticated: "auth/setIsAuthenticated",
      setAccessToken: "auth/setAccessToken",
    }),
      async nextFn() {
        if (
            this.businessnameError.status &&
            this.businessCountryError
        ) {
          this.$store.dispatch(
              "businessRegistration/set_name",
              this.businessname
              );
              this.$store.dispatch(
              "businessRegistration/set_country",
              this.businessCountry
              );
              this.$store.dispatch(
                "businessRegistration/Set_vat_label",
                this.vatLabel
              );
              this.$store.dispatch(
                "businessRegistration/set_currency",
                this.getCurrency(this.businessCountry)
              );
               this.deleteCookie("oauth_access_token");
               this.deleteCookie("oauth_refreshToken");
               this.deleteCookie("oauth_email");
               this.deleteCookie("oauth_temp_pass");
               this.deleteCookie("oauth_name");
               this.deleteCookie("oauth_msg");
               this.deleteCookie("oauth_member_of");
              this.$router.push('/onboarding')
        } else {
          this.validateForm();
        }
      },
      async checkOAuthCookies() {
      const oauth_access_token = await this.getCookie("oauth_access_token");
      const oauth_email = await this.getCookie("oauth_email");
      const oauth_temp_pass = await this.getCookie("oauth_temp_pass");
      const ori_email= decodeURIComponent(oauth_email)
      localStorage.setItem("temp_email", ori_email);
      localStorage.setItem("temp_pass", oauth_temp_pass);
      this.$store.dispatch("auth/setEmail", ori_email);
      if(!oauth_access_token){
        this.$router.push('/register')
        return
      }
      this.setIsAuthenticated(true);
      this.setAccessToken(oauth_access_token);
    },
    getCookie(name) {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      if (parts.length === 2) {
        return parts
            .pop()
            .split(";")
            .shift();
      }
    },
    deleteCookie(name) {
      document.cookie = name + `=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;domain=.${this.domain}`;
     
    },
      getCurrency(country){
        let currency;
        switch(country){
          case 'New Zealand':
            currency='NZD'
            break
          case 'Australia':
            currency='AUD'
            break
          case 'Fiji':
            currency='FJD'
            break 
          default:
            currency='NZD'   
        }
  
        return currency
      }
    },
  };
  </script>
  
  <style scoped>
  .digit-box {
    height: 4rem;
    width: 4rem;
    border: 2px solid #d3d3d3;
    display: inline-block;
    border-radius: 5px;
    margin: 5px;
    text-align: center;
    /* padding: 15px; */
    font-size: 30px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 4px 3px 0px;
  }
  
  .digit-box:focus {
    outline: 2.5px solid #525252;
  }
  
  /* .digit-box:last-child{
     outline: none;
  } */
  
  /* .pinBox {
    --width: 296px;
    --height: 74px;
    display: inline-block;
    position: relative;
    width: var(--width);
    height: var(--height);
    background-image: url(https://i.stack.imgur.com/JbkZl.png);
  } */
  
  /* .pinEntry {
    position: absolute;
    padding-left: 21px;
    font-size: var(--spacing);
    height: var(--height);
    letter-spacing: var(--spacing);
    background-color: transparent;
    border: 0;
    outline: none;
    clip: rect(0px, calc(var(--width) - 21px), var(--height), 0px);
  } */
  .login-container-card {
    width: 80%;
    min-width: 300px;
    min-height: 400px;
    max-width: 600px;
  }
  
  .login-container-card .register-button {
    opacity: 1;
    transition: opacity 200ms ease-in, box-shadow 200ms ease-in,
    color 200ms ease-in;
  }
  
  .login-container-card .register-button:hover,
  .register-google-button:hover {
    background: var(dark-purple);
    box-shadow: inset 0px 0px 10px #ffffff;
    border: none;
  }
  
  .btn-disabled {
    background: rgba(11, 155, 125, 0.2); /* light green */
    color: #fff;
    border: rgba(11, 155, 125, 0.2);
    cursor: not-allowed;
  }
  
  .g-logo {
    width: 23px;
    position: absolute;
    margin-right: 400px;
  }
  .no-fill-icon {
    fill: lightgray !important;
    margin-left: 5px;
  }
  /* Medium devices (tablets, less than 992px) */
  @media (max-width: 991.98px) {
    .login-container-card {
      min-height: fit-content;
    }
  }
  
  /* Extra small devices (portrait phones, less than 576px) */
  @media (max-width: 575.98px) {
    .login-container-card {
      width: 90%;
    }
  }
  </style>
  