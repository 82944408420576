<template>
  <InitLayout :selectedLanguage="!isVerify ? selectedLanguage.name : 'English'">
    <b-row
      class="d-flex flex-row align-items-end justify-content-end w-100  px-5"
      no-gutters
    >
      
      <!-- <b-col cols="4" md="2">
        <FormSelect
          groupId="no-of-customers-group"
          id="no-of-customers-group-input"
          class="text-prime-gray rounded-2 "
          v-model="selectedLanguage"
          :whiteBG="false"
          :options="languageOptions"
          form="login-form"
          @input="changeLanguage"
        ></FormSelect>
      </b-col> -->
      <b-dropdown  variant="white" v-if="!isVerify"
                toggle-class="d-flex flex-row align-items-center justify-content-center mx-0  my-lg-0 text-main-green border border-main-green text-main-green export-btn"
                :text="selectedLanguage ? selectedLanguage.name || 'English' : 'Language'" right>
        <b-dropdown-item v-for="language in languages" :key="language.code" @click="changeLanguage(language)">
         
          <span :class="language.flag"></span>
          
          {{ language.name }}
        </b-dropdown-item>
      </b-dropdown>
    </b-row>
    <b-card
        no-body
        class="d-flex flex-column align-items-center justify-content-center bg-transparent border-0 rounded-0 px-1 px-lg-4 py-3 login-container-card"
    >
      <!-- login form -->
      <template v-if="!isVerify">
        <form
            id="login-form"
            class="d-flex flex-column align-items-start justify-content-center px-2 py-md-2 px-lg-1 w-100 form-container"
        >
          <b-row
              class="d-flex flex-row align-items-center justify-content-center w-100"
              no-gutters
          >
            <b-col cols="12" md="10">
              <b-row
                  class="d-flex flex-row flex-nowrap align-items-center justify-content-center justify-self-center mb-3"
                  no-gutters
              >
            <span
                class="font-primary font-weight-normal text-main-green text-center "
            >By signing up to the free {{trialPeriodDays}} day trial period, enter the promo code <strong>FREEFIGA</strong> to extend the free trial period to {{ freeTrailEndDate }}</span>
              </b-row>
              <b-row
                  class="d-flex flex-row flex-nowrap align-items-center justify-content-center justify-self-center mb-3"
                  no-gutters
              >
            <span
                class="font-primary font-weight-normal text-main-green text-center "
            >Look out for the 'Promo Code' box in the Checkout section when purchasing your package.</span>
              </b-row>
              <b-row class=" d-flex flex-row flex-nowrap align-items-center justify-content-start"
                     no-gutters>
                <h3 class="text-left text-main-green font-primary font-weight-bold">
                  {{ selectedLanguage.name === 'Samoan' ?  ' Saini luga' : 'Sign Up' }}
                  
                </h3>
                <!-- Add Tooltip to the "i" icon -->
                <b-tooltip target="info-icon-signup" placement="top">
                  For manual registration, a verification code will be sent to your email.
                </b-tooltip>

                <!-- "i" icon with the id "info-icon" -->
                <b-icon
                    id="info-icon-signup"
                    icon="info-circle-fill"
                    variant="info"
                    size="sm"
                    class="no-fill-icon"
                ></b-icon>
              </b-row>


              <b-row
                  class="d-flex flex-row flex-nowrap align-items-center justify-content-start mb-3"
                  no-gutters
              >
                <span
                    class="text-left text-gray-750 font-primary font-weight-normal"
                >
                {{ selectedLanguage.name === 'Samoan' ?  'Ua uma ona iai sau tusi tupe?': 'Already have an account ?'  }}
                  
                </span>
                <b-link
                    :href="loginUri"
                    class="font-primary font-weight-normal text-main-green pl-2"
                >
                {{ selectedLanguage.name === 'Samoan' ? 'Saini i totonu':'Sign In'  }}
                
                </b-link>
              </b-row>
            </b-col>
          </b-row>
          <!-- full row input (Username) -->
          <b-row
              class="d-flex flex-row align-items-center justify-content-center w-100"
              no-gutters
          >
            <b-col cols="12" md="10">
              <FormInput
                  groupId="user-name-group"
                  id="user-name-group-input"
                  class="text-prime-gray rounded-2"
                  :state="nameError.status"
                  :invalidFeedback="`${nameError.message}`"
                  :label="selectedLanguage.name === 'Samoan' ?  'Suafa' :'Name' "
                  
                  v-model="name"
                  isRequired
                  :whiteBG="false"
                  trim
                  form="login-form"
                  type="text"
              ></FormInput>
            </b-col>
          </b-row>
          <b-row
              class="d-flex flex-row align-items-center justify-content-center w-100"
              no-gutters
          >
            <b-col cols="12" md="10">
              <FormInput
                  groupId="business-name-group"
                  id="business-name-group-input"
                  class="text-prime-gray rounded-2"
                  :state="businessnameError.status"
                  :invalidFeedback="`${businessnameError.message}`"
                  :label="selectedLanguage.name === 'Samoan' ?  'Fa\'aopoopo igoa o lau pisinisi' : 'Add your business name' "
                  
                  v-model="businessname"
                  isRequired
                  :whiteBG="false"
                  trim
                  form="login-form"
                  type="text"
              ></FormInput>
            </b-col>
          </b-row>
          <b-row
              class="d-flex flex-nowrap flex-row align-items-center justify-content-center w-100 m-0"
              no-gutters
          >
            <b-col cols="12" md="10">
              <FormSelect
                  groupId="industry-group"
                  id="industry-group-input"
                  class="text-prime-gray rounded-2"
                  style="margin-bottom: 0px;"
                  
                  :label="selectedLanguage.name === 'Samoan' ?  'Filifili lou atunuu': 'Choose your country' "
                  :state="businessCountryError.status"
                  :invalidFeedback="`${businessCountryError.message}`"
                  v-model="businessCountry"
                  :whiteBG="false"
                  :options="countryList"
                  form="login-form"
              ></FormSelect>
              </b-col>
             <div class="rapper">
              <b-tooltip target="info-icon-country-select" placement="top" >
                  *by selecting this field, prices will reflect the packages in the respective country currency
                </b-tooltip> 
              <b-icon
              id="info-icon-country-select"
              icon="info-circle-fill"
              variant="info"
              size="sm"
              class="no-fill-icon"
              ></b-icon>
             </div>
          </b-row>
          <!-- full row input (Email) -->
          <b-row
              class="d-flex flex-row align-items-center justify-content-center w-100"
              no-gutters
          >
            <b-col cols="12" md="10">
              <FormInput
                  groupId="user-name-group"
                  id="user-name-group-input"
                  class="text-prime-gray rounded-2 mt-3"
                  :state="emailError.status"
                  :invalidFeedback="`${emailError.message}`"
                  :label="selectedLanguage.name === 'Samoan' ?  'Imeli' : 'Email' "
                  v-model="email"
                  placeholder="user@example.com"
                  isRequired
                  :whiteBG="false"
                  trim
                  form="login-form"
                  type="text"
              ></FormInput>
              <!-- @keypress.native="isEmail(email)" -->
            </b-col>
          </b-row>
          <!-- full row input (Password) -->
          <b-row
              class="d-flex flex-row align-items-center justify-content-center w-100"
              no-gutters
          >
            <b-col cols="12" md="10">
              <FormInputGroup
                  groupId="password-group"
                  id="password-group-input"
                  v-model="password"
                  :state="passwordError.status"
                  :invalidFeedback="`${passwordError.message}`"
                  :label="selectedLanguage.name === 'Samoan' ?  'Upu fa\'alilolilo e lē iloa e se isi': 'Password' "
                  :placeholder="selectedLanguage.name === 'Samoan' ?  'itiiti ifo 6 mataitusi': 'Minimum 6 characters'"
                  isRequired
                  :whiteBG="false"
                  :type="showPassword ? 'text' : 'password'"
                  form="login-form"
                  isAppend
              >
                <template v-slot:append>
                  <b-button
                      class="px-3 px-md-4 rounded-0"
                      variant="transparent"
                      @click="showPassword = !showPassword"
                  >
                    <b-icon
                        :icon="showPassword ? 'eye-slash' : 'eye'"
                        aria-label="Help"
                    ></b-icon>
                  </b-button>
                </template>
              </FormInputGroup>
            </b-col>
          </b-row>
          <!-- full row input (Confirm Password) -->
          <b-row
              class="d-flex flex-row align-items-center justify-content-center w-100"
              no-gutters
          >
            <b-col cols="12" md="10">
              <FormInputGroup
                  groupId="password-group"
                  id="password-group-input"
                  v-model="confirmPassword"
                  :state="confirmPasswordError.status"
                  :invalidFeedback="`${confirmPasswordError.message}`"
                  :label="selectedLanguage.name === 'Samoan' ?  'Fa\'amaonia upu fa\'alilolilo': 'Confirm Password'"
                  
                  :placeholder="selectedLanguage.name === 'Samoan' ?  'itiiti ifo 6 mataitusi' :'Minimum 6 characters' "
                  isRequired
                  :whiteBG="false"
                  :type="showconfirmPassword ? 'text' : 'password'"
                  form="login-form"
                  isAppend
              >
                <template v-slot:append>
                  <b-button
                      class="px-3 px-md-4 rounded-0"
                      variant="transparent"
                      @click="showconfirmPassword = !showconfirmPassword"
                  >
                    <b-icon
                        :icon="showconfirmPassword ? 'eye-slash' : 'eye'"
                        aria-label="Help"
                    ></b-icon>
                  </b-button>
                </template>
              </FormInputGroup>
            </b-col>
          </b-row>

          <!-- error status -->
          <b-row
              v-if="loginError.status"
              class="d-flex flex-row align-items-center justify-content-center w-100"
              no-gutters
          >
            <b-col cols="12" md="10">
              <span class="text-danger text-small text-center">{{
                  this.loginError.message
                }}</span>
            </b-col>
          </b-row>

          <!-- form action -->
          <b-row
              class="d-flex flex-row align-items-center justify-content-center w-100"
              no-gutters
          >
          <b-row
              class="d-flex flex-row align-items-center justify-content-center w-100 pt-2 pb-3"
              no-gutters
          >
            <b-form-checkbox
              v-model="isTermsChecked"
              class="mr-2"
            ></b-form-checkbox>
            <span
            >
           
            {{ selectedLanguage.name === 'Samoan' ?  'E ala i le sainia e te ioeina i ' :'  By signing up I agree to the'}}
              <a
                  href="https://figa.co.nz/terms-and-condition/"
                  target="_blank"
                  class="text-main-green"
              >
              {{ selectedLanguage.name === 'Samoan' ?  'tu\'utu\'uga ma aiaiga': ' terms and conditons'  }}
                
              </a></span
            >
          </b-row>
            <!-- login button -->
            <b-col
                class="d-flex flex-column align-items-center justify-content-center px-0 py-2"
                cols="12"
                md="10"
            >
              <FormButton
                  isBlock
                  variant="main-green"
                  isPill
                  type="submit"
                  :isLoading="isLoader"
                  @click.native="signUpFn"
                  :class="{ 'btn-disabled': !isFormValid }"
                  :disabled="!isFormValid"
              ><span class="text-white">
                {{ selectedLanguage.name === 'Samoan' ? ' Saini luga' : 'Sign Up' }}
              </span></FormButton
              >
            </b-col>
          </b-row>
          
          <b-row
              class="d-flex flex-row align-items-center justify-content-center w-100 mt-2"
              no-gutters
          >
            <b-col cols="12" md="10">
              <b-row
                  class="d-flex flex-row align-items-center justify-content-start"
                  no-gutters
              >
                <hr style="width: 45%; text-align: left; margin-left: 0"/>
                <span class="text-gray-550 text-left">OR</span>
                <hr style="width: 45%; text-align: right; margin-right: 0"/>
              </b-row>
            </b-col>
          </b-row>
          <!-- form action Google -->
          <b-row
              class="d-flex flex-row align-items-center justify-content-center w-100"
              no-gutters
          >
            <!-- Register button -->
            <b-col
                class="d-flex flex-column align-items-center justify-content-center px-0 py-2"
                cols="12"
                md="10"
                @click="googleSignUp"
            >
              <FormButton
                  variant="main-green"
                  isBlock
                  isPill
                  class="font-weight-normal"
              >
                <img src="@/assets/google-logo.png" class="g-logo"/>
                <span class="text-center">
                  {{ selectedLanguage.name === 'Samoan' ?  'Saini i le google' : ' Sign Up with Google'  }}
                </span></FormButton
              >
            </b-col>
            <div class="rapper">
              <!-- Add Tooltip to the "i" icon -->
              <b-tooltip target="info-icon" placement="top">
                Google Sign Up is hassle-free. No verification code needed.
              </b-tooltip>

              <!-- "i" icon with the id "info-icon" -->
              <b-icon id="info-icon" icon="info-circle-fill" variant="info" size="sm" class="no-fill-icon"></b-icon>
            </div>
          </b-row>
          
        </form>
        <div>
          <b-modal v-model="error_model" centered title="Registration failed!" hide-footer
                   header-bg-variant="danger" @close="CloseModal"
                   header-text-variant="light">
            <p class="my-4">{{ oauthErrorMessage }}</p>
            <p class="my-4" v-if="oauthErrorMessage === 'This email address is already registered!'">
              Here is our <a href="/login">login page</a>, you can sign-in from there. If you forgot your password, goto
              <a href="/forgot-password">recovery page</a> to reset your password.
            </p>
          </b-modal>
        </div>
      </template>
      <!-- Verify Email -->
      <template v-else>
        <b-row
            class="d-flex flex-row align-items-center justify-content-center w-100"
            no-gutters
        >
          <b-col
              class="d-flex flex-column align-items-center justify-content-center px-0 py-2"
              cols="12"
              md="10"
          >
            <h4>Enter 4 Digit Verification Code</h4>
            <p style="font-weight: bold;">
              We've send a verification code to your email address
            </p>
          </b-col>
          <b-row
              class="d-flex flex-row align-items-center justify-content-center w-100"
              no-gutters
          >
            <b-col
                class="d-flex flex-column align-items-center justify-content-center px-0 py-2"
                cols="12"
                md="10"
            >
              <div ref="otpCont">
                <input
                    type="text"
                    class="digit-box"
                    v-for="(el, ind) in digits"
                    :key="ind"
                    :autofocus="ind === 0"
                    v-model="digits[ind]"
                    maxlength="1"
                    @input="handleKeyDown($event, ind)"
                    @keydown="deleteValue($event, ind)"
                />
              </div>
            </b-col>
          </b-row>
          <!--verify button -->
          <b-row
              class="d-flex flex-row align-items-center justify-content-center w-100 mt-3"
              no-gutters
          >
            <b-col
                class="d-flex flex-column align-items-center justify-content-center px-0 py-2"
                cols="8"
                md="5"
                @click="verifyEmail"
            >
              <FormButton
                  variant="main-green"
                  isBlock
                  isPill
                  class="font-weight-normal"
                  :disabled="isVerifyBtn"
              >
                <span class="text-center">Verify Email</span></FormButton
              >
            </b-col>
          </b-row>
          <b-row
              class="d-flex flex-row align-items-center justify-content-center w-100 mt-3"
              no-gutters
          >
            <b-col
                class="d-flex flex-column align-items-center justify-content-center px-0 py-2"
                cols="8"
                md="5"
                @click="resendVerifyCode"
            >
              <FormButton
                  variant="main-green"
                  isBlock
                  isPill
                  class="font-weight-normal"
              >
                <span class="text-center">Resend Verify Code</span></FormButton
              >
            </b-col>
          </b-row>
        </b-row>
      </template>
    </b-card>
    <ToastNotification/>
  </InitLayout>
</template>

<script>
// services
//   import { LoginNew } from "../services/login.service";
import {mapActions} from "vuex";
import axios from "axios";
import {BIcon, BTooltip} from 'bootstrap-vue';
// components
// @ is an alias to /src
import InitLayout from "@/layout/InitLayout";
import FormInput from "@/components/Form/FormInput";
import FormInputGroup from "@/components/Form/FormInputGroup";
import FormButton from "@/components/Form/FormButton";
import CheckEmail from "@/util/CheckEmail";
import {getGoogleUrl} from "@/util/auth/getGoogleUrl";
import ToastNotification from "@/components/common/ToastNotification";
import FormSelect from "@/components/Form/FormSelect";
import getPromoCodeEndDate from "../util/getPromoCodeEndDate";
import { trial_period } from "../util/trialPeriod";

export default {
  name: "Register",
  components: {
    InitLayout,
    FormInput,
    FormInputGroup,
    FormButton,
    ToastNotification,
    BIcon,
    BTooltip,
    FormSelect
  },
  data() {
    return {
      selectedLanguage: { code: '', name: 'English', flag: '' },
      isTermsChecked: false,
      freeTrailEndDate:null,
      trialPeriodDays :null,
      enteredVal: null,
      userEmail: localStorage.getItem("temp_email"),
      verificationCodeReSend: false,
      verificationCode: null,
      digits: ["", "", "", ""],
      digitCount: 4,
      isVerifyBtn: true,
      pinNumber: null,
      isVerify: false,
      isLoader: false,
      name: "",
      businessname: null,
      businessCountry: "New Zealand",
      countryList: ["New Zealand", "Australia", "Fiji"],
      vatLabel: 'GST Rate',
      email: "",
      password: "",
      confirmPassword: "",
      showPassword: false,
      showconfirmPassword: false,
      errorEmail: null,
      errorPasswor: null,
      nameError: {
        status: null,
        message: "",
      },
      businessnameError: {
        status: null,
        message: "",
      },
      businessCountryError: {
        status: null,
        message: "",
      },
      emailError: {
        status: null,
        message: "",
      },
      passwordError: {
        status: null,
        message: "",
      },
      confirmPasswordError: {
        status: null,
        message: "",
      },
      loginError: {
        status: false,
        message: "",
      },
      loginUri: "",
      oauth_redirect: process.env.VUE_APP_GOOGLE_OAUTH_REDIRECT_REGISTRATION,
      domain: process.env.VUE_APP_DOMAIN,
      oauthErrorMessage: null,
      error_model: false,
      // languageOptions: ['English', 'Samoan'],
      languages: [
      {  name: 'English', flag: 'fi fi-nz' },
          {  name: 'Samoan', flag: 'fi fi-ws' },
          // Add more languages as needed
        ],
    };
  },
  mounted() {
    this.freeTrailEndDate= getPromoCodeEndDate()
    this.trialPeriodDays = trial_period;
    try {
      if (process.env.NODE_ENV === 'production') {
        // eslint-disable-next-line no-undef
        fbq('track', 'PageView', {});
      } else {
        console.log('pixel is not available in staging or dev servers.')
      }
    } catch (e) {
      console.log('pixel tracker error!')
    }
    if (!(Object.keys(this.$route.query).length === 0)) {
      this.$route.query.isEmailVerified === "false"
          ? (this.isVerify = true)
          : "";
    }
    console.log(this.digits);
    const orginalUri = "/login";
    const next = this.$route.query.next;
    if (next) {
      this.loginUri = `${orginalUri}?next=${next}`;
    } else {
      this.loginUri = orginalUri;
    }
    this.checkOAuthCookies();
    if (!this.$store.getters['storeTempValues/getTempEmail'] && this.$route.query.isEmailVerified === "false") {
      this.$router.push("/login");
    }
    this.selectedLanguage.name = localStorage.getItem('selectedLanguage');
   
  },
  watch: {
    pinNumber(val) {
      if (val.length === 4) {
        this.isVerifyBtn = false;
      } else {
        this.isVerifyBtn = true;
      }
    },
    name(value) {
      this.loginError.status = false;
      if (value) {
        this.nameError.status = true;
      } else {
        this.nameError.status = false;
        this.nameError.message = "Please fill in the name";
      }
    },
    businessname(value) {
      if (value) {
        this.businessnameError.status = true;
      } else {
        this.isEnabled = false;
        this.businessname = undefined;
        this.businessnameError.status = false;
        this.businessnameError.message = "Please fill out this field";
      }
    },
    businessCountry(value) {
      if (value === "New Zealand" || value === "Australia") {
        this.vatLabel = "GST Rate";
      } else if (value === "Fiji") {
        this.vatLabel = "VAT Rate";
      }
    },
    email(value) {
      this.loginError.status = false;
      if (value) {
        if (CheckEmail(value) === true) this.emailError.status = true;
        else {
          this.emailError.status = false;
          this.emailError.message = "Please enter a valid email address";
        }
      } else {
        this.emailError.status = false;
        this.emailError.message = "Please fill in your email address";
      }
    },
    password(value) {
      if (value) {
        if (value.length < 6) {
          this.passwordError.status = false;
          this.passwordError.message =
              "A minimum length of 6 characters needed";
        } else {
          if (value === this.confirmPassword) {
            this.confirmPasswordError.status = true;
          }
          this.passwordError.status = true;
        }
      } else {
        this.passwordError.status = false;
        this.passwordError.message = "Please fill in your password";
      }
    },
    confirmPassword(value) {
      this.loginError.status = false;
      if (value) {
        if (value.length < 6) {
          this.confirmPasswordError.status = false;
          this.confirmPasswordError.message =
              "A minimum length of 6 characters needed";
        } else if (this.password !== value) {
          this.confirmPasswordError.status = false;
          this.confirmPasswordError.message =
              "Password should match the above one";
        } else {
          this.confirmPasswordError.status = true;
        }
      } else {
        this.confirmPasswordError.status = false;
        this.confirmPasswordError.message = "Please confirm the password";
      }
    },
  },
  computed: {
    isFormValid() {
      console.log(this.businessname)
      console.log(this.businessname!==null ||  this.businessname!==undefined)
      return (
          this.name !== "" &&
          (this.businessname!==null &&  this.businessname!==undefined)&&
          this.email !== "" &&
          this.password !== "" &&
          this.confirmPassword !== "" &&
          (this.password ===this.confirmPassword)&&
          this.isTermsChecked !== false
      );
    },
  },
  methods: {
    ...mapActions({
      signUp: "auth/signUp",
      setIsAuthenticated: "auth/setIsAuthenticated",
      setAccessToken: "auth/setAccessToken",
    }),

    hadleInput(e) {
      this.enteredVal = e
    },
    CloseModal() {
      console.log('Close Modal')
      this.deleteCookie("oauth_error");
      this.deleteCookie("oauth_error_message");
    },
    checkDigitComplete() {
      for (const elem of this.digits) {
        // console.log("🚀 ~ file: Register.vue:512 ~ checkDigitComplete ~ this.digits:", this.digits)
        if (elem == "" || elem == undefined) {
          return false;
        }
      }
      this.verificationCode = Number(this.digits.join(""));
      //remove the focus from last element
      this.$refs.otpCont.children[this.digitCount - 1].blur();
      return true;
    },
    handleKeyDown(event, index) {
      if (
          event.key !== "Tab" &&
          event.key !== "ArrowRight" &&
          event.key !== "ArrowLeft"
      ) {
        event.preventDefault();
      }

      if (new RegExp("^([0-9])$").test(event.data)) {
        this.digits[index] = event.data;
        this.$forceUpdate();
        if (this.checkDigitComplete()) this.isVerifyBtn = false;
        if (index !== (this.digitCount - 1)) {
          this.$refs.otpCont.children[index + 1].focus();
        }
      }
      console.log(this.digits)
    },
    deleteValue(event, index) {
      if (event.key === "Backspace") {
        if (this.digits[index] !== "") {
          console.log("First If");
          this.digits[index] = "";
          this.$refs.otpCont.children[index].focus();
          console.log(this.digits);
        } else {
          this.$refs.otpCont.children[index - 1].focus();
          console.log("Second If");
        }
        this.$forceUpdate();
        if (!this.checkDigitComplete()) this.isVerifyBtn = true;
        return;
      }
    },
    // ...mapGetters({}),
    async googleSignUp() {
      const from = "sign up";
      const googleUrl = getGoogleUrl(from, this.oauth_redirect);
      window.location.href = googleUrl;
    },
    async signUpFn() {
      if (
          this.emailError.status &&
          this.passwordError.status &&
          this.nameError.status &&
          this.businessnameError.status &&
          this.businessCountryError &&
          CheckEmail(this.email) &&
          this.confirmPassword === this.password
      ) {
        console.log("Signing Up with ", this.email, this.name, this.password, this.businessCountry, this.vatLabel);
        try {
          this.isLoader = true;
          let tempCurrency = this.getCurrency(this.businessCountry)
          console.log(tempCurrency)
          const response = await axios.post("/register/user", {
            email: this.email,
            name: this.name,
            password: this.password,
            businessname: this.businessname,
            businessCountry: this.businessCountry,
            vatLabel: this.vatLabel,
            currency: tempCurrency,
          });
          if (response.status == 200) {
            this.setAccessToken(response.data.access_token);
            this.setIsAuthenticated(true);
            // localStorage.setItem("temp_pass", this.password);
            // localStorage.setItem("temp_email", this.email);
            this.$store.dispatch("storeTempValues/setTempPassword",this.password);
            this.$store.dispatch("storeTempValues/setTempEmail",this.email);
            this.$store.dispatch(
                "businessRegistration/set_name",
                this.businessname
            );
            this.$store.dispatch(
                "businessRegistration/set_country",
                this.businessCountry
            );
            this.$store.dispatch(
                "businessRegistration/Set_vat_label",
                this.vatLabel
            );
            this.$store.dispatch(
                "businessRegistration/set_currency",
                this.getCurrency(this.businessCountry)
            );
            // this.$store.dispatch(
            //   "businessRegistration/set_buisness_status",
            //   'Yeah, i m running it part-time'
            // );
            // this.$store.dispatch(
            //   "businessRegistration/set_current_managing_method",
            //   'Would you like support from a Figa Business Lead'
            // );
            // this.$store.dispatch(
            //   "businessRegistration/set_current_accountant_status",
            //   "No, but I'm interested in finding one"
            // );
            // this.$store.dispatch(
            //   "businessRegistration/set_online_payments",
            //   "No, I don't"
            // );
            if (this.$route.query.next) {
              window.location.href = this.$route.query.next;
              return;
            }
            this.isVerify = true;
            let payloadNotify = {
              isToast: true,
              title: "Verify Email",
              content:
                  "Please check your email address to find verification code",
              variant: "success",
            };
            this.$store.dispatch("notification/setNotify", payloadNotify);
            // this.$router.push("/onboarding");
            return;
          }
          // else if (response.status == 409) {
          //   console.log("already exist", response.data);
          // }
          console.log("TODO :: Redirect to business registration page");
          this.$router.push("/login");
        } catch (error) {
          const {status} = error.response;
          if (status == 409)
            this.loginError.message =
                "An account exist with the provided email address.";
          else
            this.loginError.message = error.response
                ? error.response.data.msg
                : error.message;
          this.loginError.status = true;
        } finally {
          this.isLoader = false;
        }
      } else {
        this.validateForm();
      }
    },
    async verifyEmail() {
      this.isVerifyBtn = true;
      const email = this.$store.getters['storeTempValues/getTempEmail'];
      const verifyCode = this.verificationCode;
      const verificationCodeReSend = this.verificationCodeReSend;
      const payload = {email, verifyCode, verificationCodeReSend};
      try {
        const response = await axios.post("/register/verifyEmail", payload);
        if (response.status === 200) {
          let payloadNotify = {
            isToast: true,
            title: "Verify Email",
            content: "Your Email Verified Successfully",
            variant: "success",
          };
          this.$store.dispatch(
              "businessRegistration/set_name",
              response.data.data.businessname
          );
          this.$store.dispatch(
              "businessRegistration/set_country",
              response.data.data.businessCountry
          );
          this.$store.dispatch(
              "businessRegistration/Set_vat_label",
              response.data.data.vatLabel
          );
          this.$store.dispatch(
              "businessRegistration/set_currency",
              response.data.data.currency
          );

          this.$store.dispatch("notification/setNotify", payloadNotify);
          if (response.data.isValidBusiness) {
            this.$router.push("/");
            return;
          }
          this.$router.push("/onboarding");
        }
      } catch (error) {
        console.log(error.response.data.msg);
        const {status} = error.response;
        if (status === 400) {
          let payloadNotify = {
            isToast: true,
            title: "Verify Email",
            content: error.response.data.msg,
            variant: "danger",
          };
          this.$store.dispatch("notification/setNotify", payloadNotify);
        }
      }
      this.isVerifyBtn = false;
    },
    async resendVerifyCode() {
      try {
        const email = this.$store.getters['storeTempValues/getTempEmail'];
        const verificationCodeReSend = true;
        const payload = {email, verificationCodeReSend};
        const response = await axios.post("/register/verifyEmail", payload);
        if (response.status === 200) {
          let payloadNotify = {
            isToast: true,
            title: "Verify Email",
            content: "Verification code send to your email",
            variant: "success",
          };
          this.$store.dispatch("notification/setNotify", payloadNotify);
        }

      } catch (error) {
        console.log(error);
      }
    },

    loginFn() {
      if (this.email && this.password && CheckEmail(this.email)) {
        let payload = {
          // UserType: "Admin",
          Email: this.email,
          Password: this.password,
        };
        console.log(payload);
        this.isLoader = true;
        //   LoginNew(payload)
        //     .then((res) => {
        //       console.log(res.data);
        //       if (res.data.message == "success") {
        //         this.isLoader = false;
        //         // this.$store.dispatch("register/setVendor", res.data.content);
        //         console.log(res.data.content);
        //         let payloadNotify = {
        //           isToast: true,
        //           title: "Administrator Login",
        //           content: "Logged in successfully",
        //           variant: "success",
        //         };

        //         this.$store.dispatch("notification/setNotify", payloadNotify);
        //         localStorage.setItem("masterAdmin", JSON.stringify(res.data.content));

        //         if (res.data.content.adminType == "MANAGER") {
        //           localStorage.setItem("selectedCountry", res.data.content.country[0].countryCode.toLowerCase());

        //           localStorage.setItem("selectedCountryIndex", 0);
        //         }

        //         this.$router.replace("/");
        //       } else {
        //         this.isLoader = false;
        //         this.loginError.status = true;
        //         this.loginError.message = res.data.details;
        //       }
        //     })
        //     .catch((err) => {
        //       console.log(err);
        //     });
      } else {
        this.validateForm();
      }
    },
    validateForm() {
      if (!this.name) {
        this.nameError.status = false;
        this.nameError.message = "Please fill in the name";
      }
      if (!this.email) {
        this.emailError.status = false;
        this.emailError.message = "Please fill in your email address";
      }

      if (!this.password) {
        this.passwordError.status = false;
        this.passwordError.message = "Please fill in your password";
      } else if (this.password < 6) {
        this.passwordError.status = false;
        this.passwordError.message = "A minimum length of 6 characters needed";
      }

      if (!this.confirmPassword) {
        this.confirmPasswordError.status = false;
        this.confirmPasswordError.message = "Please confirm the password";
      } else if (this.confirmPassword < 6) {
        this.confirmPasswordError.status = false;
        this.confirmPasswordError.message =
            "A minimum length of 6 characters needed";
      } else if (this.confirmPassword !== this.password) {
        this.confirmPasswordError.status = false;
        this.confirmPasswordError.message =
            "Password should match the above one";
      }
      if (!this.email) {
        this.businessnameError.status = false;
        this.businessnameError.message = "Please fill enter a business name";
      }
    },
    async checkOAuthCookies() {
      this.isLoader = true;
      const oauth_access_token = await this.getCookie("oauth_access_token");
      const oauth_email = await this.getCookie("oauth_email");
      const oauth_temp_pass = await this.getCookie("oauth_temp_pass");
      const oauth_error = await this.getCookie("oauth_error");
      const oauth_error_messsage = await this.getCookie("oauth_error_message");
      if (oauth_error && oauth_error_messsage) {
        this.oauthErrorMessage = decodeURIComponent(oauth_error_messsage);
        this.error_model = true;
        await this.deleteCookie("oauth_error");
        await this.deleteCookie("oauth_error_message");
        this.isLoader = false;
      }
      if (oauth_access_token && oauth_email && oauth_temp_pass) {
        this.setAccessToken(oauth_access_token);
        this.setIsAuthenticated(true);
        // localStorage.setItem("temp_pass", oauth_temp_pass);
        // localStorage.setItem("temp_email", decodeURIComponent(oauth_email));
        this.$store.dispatch("storeTempValues/setTempPassword",oauth_temp_pass);
        this.$store.dispatch("storeTempValues/setTempEmail",decodeURIComponent(oauth_email));
        await this.deleteCookie("oauth_access_token");
        await this.deleteCookie("oauth_email");
        await this.deleteCookie("oauth_temp_pass");
        this.$router.push("/onboarding");
      } else {
        this.isLoader = false;
      }
    },
    getCookie(name) {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      if (parts.length === 2) {
        return parts
            .pop()
            .split(";")
            .shift();
      }
    },
    deleteCookie(name) {
      document.cookie = name + `=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;domain=.${this.domain}`;
    },
    getCurrency(country) {
      let currency;
      switch (country) {
        case 'New Zealand':
          currency = 'NZD'
          break
        case 'Australia':
          currency = 'AUD'
          break
        case 'Fiji':
          currency = 'FJD'
          break
        default:
          currency = 'NZD'
      }

      return currency
    },
    changeLanguage(language) {
      this.selectedLanguage.name = language.name;
      localStorage.setItem('selectedLanguage', this.selectedLanguage.name);
    }
  },
};
</script>

<style scoped>
.form-container{
  position: relative;
}
.rapper{
  position: absolute;
  left:92%;
}


.digit-box {
  height: 4rem;
  width: 4rem;
  border: 2px solid #d3d3d3;
  display: inline-block;
  border-radius: 5px;
  margin: 5px;
  text-align: center;
  /* padding: 15px; */
  font-size: 30px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 4px 3px 0px;
}

.digit-box:focus {
  outline: 2.5px solid #525252;
}

/* .digit-box:last-child{
   outline: none;
} */

/* .pinBox {
  --width: 296px;
  --height: 74px;
  display: inline-block;
  position: relative;
  width: var(--width);
  height: var(--height);
  background-image: url(https://i.stack.imgur.com/JbkZl.png);
} */

/* .pinEntry {
  position: absolute;
  padding-left: 21px;
  font-size: var(--spacing);
  height: var(--height);
  letter-spacing: var(--spacing);
  background-color: transparent;
  border: 0;
  outline: none;
  clip: rect(0px, calc(var(--width) - 21px), var(--height), 0px);
} */
.login-container-card {
  width: 80%;
  min-width: 300px;
  min-height: 400px;
  max-width: 600px;
}

.login-container-card .register-button {
  opacity: 1;
  transition: opacity 200ms ease-in, box-shadow 200ms ease-in,
  color 200ms ease-in;
}

.login-container-card .register-button:hover,
.register-google-button:hover {
  background: var(dark-purple);
  box-shadow: inset 0px 0px 10px #ffffff;
  border: none;
}

.btn-disabled {
  background: rgba(11, 155, 125, 0.2); /* light green */
  color: #fff;
  border: rgba(11, 155, 125, 0.2);
  cursor: not-allowed;
}

.g-logo {
  width: 23px;
  position: absolute;
  margin-right: 400px;
}

.no-fill-icon {
  fill: lightgray !important;
  margin-left: 5px;
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
  .login-container-card {
    min-height: fit-content;
  }
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .login-container-card {
    width: 90%;
  }
}

@media (max-width: 766px) {
  .rapper{
  left:100%;
  /* background-color: red; */
}
}
</style>
